import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`500 Burpees for time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`April Fools!!`}</em></strong></p>
    <p>{`Bench Press 5×2\\@80% 1RM w/ :01 pause on chest`}</p>
    <p>{`Strict CTB Pullups 5×2 (as fast and high as possible on ascent)`}</p>
    <p>{`then, 4 rounds for time:`}</p>
    <p>{`20-Ring Pushups`}</p>
    <p>{`20-KBS’s (53/35)(rx+ 70/53)`}</p>
    <p>{`20-K2E’s`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      